.App {
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  height: 100%;
}

body {
  position: absolute;

  margin: 0;
  padding: 0;
  width: 100%;
}

.loading-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
}

.loading-logo {
  width: 12.5rem;
  height: 3.75rem;
  margin-right: -25px;
}