.textarea-promt {
    display: flex;
    resize: none;
    background-color: transparent;
    color: #ffffff;
    height: 64px;
    width: 95%;
    padding: 1.25rem 1.25rem 0 0;
    max-height: 16.25rem;
    overflow-y: hidden;
    text-align: left;
    align-self: center;
    align-items: center;
    justify-content: center;
}

textarea:focus {
    outline: none;
}

.textarea-promt::-webkit-scrollbar {
    width: .1875rem;
}

.textarea-promt::-webkit-scrollbar:horizontal {
    height: .5rem;
    width: 1rem
}

.textarea-promt::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9999px
}

.textarea-promt::-webkit-scrollbar-thumb {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    background-color: var(--light-theme-border-color);
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
    border-radius: 9999px;
    border-width: 1px;
    height: 50%;
}

.textarea-promt::-webkit-scrollbar-thumb:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(172, 172, 190, var(--tw-bg-opacity))
}

svg.text-muted {
    color: #ffffff !important;
    background-color: #6c757d;
    padding: 5px;
}

svg.text-success {
    color: hwb(328 16% 35%) !important;
    background-color: #3C1333;
    padding: 5px;
}

.btn-neutral {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    border-color: rgba(0, 0, 0, .1);
    border-width: 1px;
    color: rgba(64, 65, 79, var(--tw-text-opacity));
    font-size: .875rem;
    line-height: 1.25rem;
}

.btn-neutral:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 236, 241, var(--tw-bg-opacity));
}

.btn {
    align-items: center;
    border-color: transparent;
    border-radius: 0.25rem;
    border-width: 1px;
    display: inline-flex;
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.75rem;
    pointer-events: auto;
}

.btn-play {
    padding: 0.5rem;
}

.send-btn {
    align-items: center;
    justify-content: center;
}

.stop-icon {
    width: .9375rem;
    height: .9375rem;
    border-radius: .1875rem;
}

.disclaimer-text {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1rem;
    font-family: "Google Sans", "Helvetica Neue", sans-serif;
    letter-spacing: .1px;
    margin-bottom: .3125rem;
}