.chat-list {
    height: auto;
    max-height: 73vh;
    min-height: 70%;
    width: 100%;
    overflow-y: scroll;
}

.chat-list.stop {
    overflow: hidden;
    padding-right: 4px;
}

.chat-list::-webkit-scrollbar {
    width: .25rem;
}

.chat-list::-webkit-scrollbar-track {
    background: transparent;
}

.chat-list::-webkit-scrollbar-thumb {
    background-color: var(--light-theme-border-color);
    height: 50%;
    border-radius: 1.25rem;
}

.sidebar-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.sidebar-list-title-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

.sidebar-list-title {
    font-weight: 700;
    font-size: 14px;
}

.sidebar-list-date {
    font-weight: 400;
    font-size: 14px;
    text-align: start;
}

.sidebar-chat-item {
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 12px;
    padding: 10px 0 10px 0;
    gap: 5px;
}

.sidebar-chat-item-fixed-clean {
    width: 100%;
    height: auto;
    border-radius: 12px;
    gap: 5px;
}

.sidebar-list-container-ul {
    width: 100%;
    padding: 0 10px 0 0;
}

.sidebar-chat-item-li {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: .625rem;
    max-height: 4rem;
    justify-content: flex-start;
    align-items: center;
    gap: .3125rem;
}

.sidebar-chat-item-li-selected {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: .75rem;
    padding: .625rem;
    max-height: 4rem;
    justify-content: flex-start;
    align-items: center;
    gap: .3125rem;
}

.sidebar-chat-item-three-options-container {
    display: flex;
    width: 30%;
    min-height: 2.75rem;
    max-width: 2.75rem;
    max-height: 2.75rem;
    border-radius: 3.125rem;
    padding: .625rem;
    gap: .625rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sidebar-chat-item-three-options-container-selected {
    display: flex;
    width: 30%;
    min-height: 2.75rem;
    max-width: 2.75rem;
    max-height: 2.75rem;
    border-radius: 3.125rem;
    padding: .625rem;
    gap: .625rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sidebar-chat-item-three-options-container-mobile {
    display: none;
    width: 30%;
    min-height: 2.75rem;
    max-width: 2.75rem;
    max-height: 2.75rem;
    border-radius: 3.125rem;
    padding: .625rem;
    gap: .625rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.sidebar-chat-item-three-options {
    align-self: center;
}

.sidebar-chat-item-three-options-selectors {
    position: absolute;
    flex-direction: column;
    margin-right: -14.375rem;
    margin-top: 3.75rem;
    height: 7.625rem;
    width: 12.5625rem;
    border-radius: 12px;
    padding: .625rem;
    gap: .3125rem;
    z-index: 30;
}

.float-options {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 200px;
    padding: .9375rem;
    gap: 5px;
    align-items: flex-start;
    justify-content: center;
    border-radius: .75rem;
    z-index: 1000;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.loader-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.chats-fixed-lists {
    display: flex;
    border-bottom: solid 1px rgba(255, 255, 255, 0.15);
    gap: 5px;
}

.chats-fixed-dark-lists {
    display: flex;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    gap: 5px;
}

.skeleton-icon {
    margin-top: .625rem;
    width: .375rem;
    height: .375rem;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRolling 2s linear infinite;
  }
  
  @keyframes shadowRolling {
    0% {
      box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    12% {
      box-shadow: 100px 0  , 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    25% {
      box-shadow: 110px 0  , 100px 0  , 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }
    36% {
      box-shadow: 120px 0  , 110px 0  , 100px 0  , 0px 0 rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 130px 0  , 120px 0  , 110px 0  , 100px 0  ;
    }
    62% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0  , 120px 0  , 110px 0  ;
    }
    75% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0  , 120px 0  ;
    }
    87% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0  ;
    }
    100% {
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
    }
  }

@media screen and (max-width: 1200px) {
    .chat-list {
        max-height: 75vh;
        min-height: 70%;
        width: 100%;
        padding: 0px 10px 0px 10px;
        margin-top: 1.875rem;
    }

    .sidebar-chat-item-three-options-container {
        display: none;
    }

    .sidebar-chat-item-three-options-container-selected {
        display: none;
    }

    .sidebar-chat-item-three-options-container-mobile {
        display: flex;
    }

    .float-options {
        display: none !important;
    }
}