.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 68px;
    padding: 10px 40px 10px 30px;
    background-color: #F3F8FF;
    z-index: 100;
}

.navbar-container-shadow {
    box-shadow: rgba(99, 99, 99, 0.13) 0px 2px 8px 0px;
}

.navbar-container .logo-container {
    display: flex;
    flex: 7;
    align-items: center;
    min-width: 250px;
}

.navbar-container .logo-athena {
    width: 10.625rem;
    height: 3rem;
    min-width: 7.2rem;
    min-height: 3rem;
}

.navbar-container .menu-responsive-btn {
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    padding: 10px;
}

.navbar-container .buttom-container {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.navbar-container .buttom-container .buttom-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 194px;
    height: 40px;
    gap: 10px;
}

.navbar-container .buttom-container .buttom-wrapper .blackmode-btn {
    display: flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--light-theme-surface-color);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.navbar-container .buttom-container .buttom-wrapper .blackmode-btn-sun {
    display: flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #242424;
}

.navbar-container .buttom-container .buttom-wrapper .export-btn {
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    padding: 10px;
}

.navbar-container .buttom-container .buttom-wrapper .person-btn {
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    padding: 10px;
}


.navbar-container .buttom-container .buttom-wrapper .signin-btn {
    background-color: var(--general-theme-primary-bg-color);
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 40px;
    border-radius: 4px;
    gap: 8px;
    color: var(--general-theme-primary-text-color);
    font-weight: 400;
    font-size: 16px;
}

.navbar-container .buttom-container .user-info-light {
    width: auto;
    max-width: 200px;
    height: 40px;
    border-radius: 4px;
    align-items: center;
    gap: 8px;
    color: #303030;
    font-weight: 400;
    font-size: 16px;
}

.navbar-container .buttom-container .user-info-dark {
    width: auto;
    max-width: 200px;
    height: 40px;
    border-radius: 4px;
    align-items: center;
    gap: 8px;
    color: var(--general-theme-primary-text-color);
    font-weight: 400;
    font-size: 16px;
}

.chart-icon {
    display: flex;
    width: 80%;
    height: 80%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.version-navbar {
    width: auto;
    margin-left: -25px;
    font-size: 12px;
    text-align: end;
    padding-top: .3125rem;
}

.box-wrapper-icon {
    display: flex;
    animation: fadeInLeft 0.5s;
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }

    to {
        opacity: 1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}

@-moz-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }

    to {
        opacity: 1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translatex(-100px);
        -moz-transform: translatex(-100px);
        -o-transform: translatex(-100px);
        transform: translatex(-100px);
    }

    to {
        opacity: 1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}

.in-left {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-duration: 1s;
    animation-delay: 1s;
}

@media screen and (max-width: 700px) {

    .navbar-container {
        padding: 10px 20px 10px 20px;
    }

    .navbar-container .logo-container {
        margin-right: 10px;
        min-width: 100px;
        min-width: 150px;
    }

    .navbar-container .buttom-container {
        display: flex;
        flex: 2;
        align-items: center;
        justify-content: flex-end;
        gap: .625rem;
    }

    .navbar-container .buttom-container .buttom-wrapper {
        display: flex;
        justify-content: flex-end;
        width: auto;
        min-width: 10rem;
        height: 2.5rem;
        gap: .625rem;
    }

    .navbar-container .logo-athena {
        width: auto;
        height: auto;
    }

    .version-navbar {
        margin-left: -20px;
    }

}

@media screen and (max-width: 590px) {

    .box-wrapper-icon {
        display: none !important;
    }

}