.main-container {
    display: flex;
    flex: 1;
    width: 100%;
    height: 90%;
    flex-direction: row;
    overflow-y: none;
    justify-content: center;
}

.chat-list-load {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 73vh;
    min-height: 81%;
    width: 100%;
}

.main-info-wrapper {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    padding: 0 1.875rem 4.375rem .625rem;
}

.main-info-wrapper.max-imension {
    width: 90%;
}

.main-info-wrapper-content {
    display: flex;
    width: 100%;
    max-width: 92.875rem !important;
    height: 100%;
    max-height: 85vh;
    border-radius: 1.125rem;
    flex-direction: column;
    padding: .625rem 1.25rem .625rem 1.25rem;
    align-items: center;
}

.main-info-wrapper-content-top {
    display: flex;
    height: 100%;
    width: 100%;
    max-height: 90%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
}

.main-info-wrapper-content-top::-webkit-scrollbar {
    width: 4px;
}

.main-info-wrapper-content-top::-webkit-scrollbar-track {
    background: transparent;
}

.main-info-wrapper-content-top::-webkit-scrollbar-thumb {
    background-color: var(--light-theme-border-color);
    height: 10%;
    max-height: 3.125rem;
    border-radius: 1.25rem;
}

.main-info-component {
    height: 60%;
    width: 80%;
    max-width: 71.25rem;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 10%;
}

.main-info-component-top {
    height: 50%;
    width: 100%;
    min-height: 9.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
}

.main-info-component-top-icon {
    height: 7.5rem;
    width: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center
}

.main-info-component-top-texts {
    height: 5.4375rem;
    width: auto;
    max-width: 42.5625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.main-info-component-top-texts-title {
    font-size: 4.0625rem;
    font-weight: 700
}

.main-info-component-top-texts-p {
    font-size: 1.25rem;
    font-weight: 400
}

.main-info-component-box-content {
    height: auto;
    min-height: 60%;
    width: 100%;
    padding: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
}

.main-info-component-box-item {
    width: 16.875rem;
    height: 10.9375rem;
    border-radius: .75rem;
    padding: 1.25rem;
    gap: .625rem;
}

.main-info-component-box-item h4 {
    font-size: 1rem;
    font-weight: 600;
}

.main-info-component-box-item p {
    font-size: .875rem;
    font-weight: 400;
    text-align: left;
}

.main-info-component-box-item-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    min-height: 2rem;
    max-height: 20%;
    gap: .5rem;
}

.main-info-component-box-item-top-icon {
    width: 2rem;
    height: 2rem;
}

.main-info-component-box-item-bottom {
    width: 100%;
    height: 80%;
    min-height: 50%;
    margin-top: .3125rem;
}

.main-info-wrapper-content-bot {
    display: flex;
    height: 4rem;
    min-height: 1.25rem;
    max-height: 14.375rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1.875rem;
}

.main-info-wrapper-content-bot-stop-options-box {
    display: flex;
    width: 80%;
    height: 2.75rem;
    gap: .625rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem;
}

.main-info-wrapper-content-bot-stop-options-box button {
    display: flex;
    font-display: row;
    align-items: center;
    justify-content: center;
    gap: .625rem;
    cursor: pointer;
}

.main-info-wrapper-content-bot-question-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 71.25rem;
    height: auto;
    min-height: 4rem !important;
    max-height: 18.75rem;
    border-radius: 3.125rem;
    margin-bottom: 1.25rem;
    padding: 1.25rem 1.875rem 1.25rem 1.875rem;
    align-items: center;
    justify-content: center;
}

.main-info-wrapper-content-bot-question-box-input {
    display: flex;
    width: 100%;
    height: auto;
    min-height: 4rem !important;
    max-height: 16.25rem !important;
    padding: .625rem .625rem .625rem .625rem;
    align-items: center;
    justify-content: center;
}

.main-info-wrapper-content-bot-question-box-input-textarea {
    display: flex;
    width: 100%;
    height: auto;
    min-height: 4rem !important;
    max-height: 16.25rem !important;
    padding: .625rem .625rem .625rem .625rem;
    align-items: center;
    justify-content: center;

}

.main-info-wrapper-content-bot-question-box-input-tests {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: auto;
    min-height: 4rem;
    max-height: 12.5rem;
    border-radius: 3.125rem;
    padding: 1.25rem;
    text-align: start;


}

.main-info-wrapper-content-bot-question-box-send {
    display: flex;
    width: 10%;
    height: 100%;
    padding: .625rem;
    justify-content: flex-end;
    align-items: center;
}

.main-chat-item-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 4rem;
}

.main-chat-item-btn-container {
    margin-left: .3125rem;
    width: 100%;
}

.main-chat-item-btn-container.reduce {
    margin-left: -0.0625rem;
    width: 100%;
}

.main-chat-item-btn-selected {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.main-chat-item-btn-responsive {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.main-chat-item-btn-responsive p {
    display: flex;
    padding-top: .125rem;
}

.main-chat-item-btn-responsive.selected {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.main-chat-item-btn-responsive.selected p {
    display: none;
}

.min-chat-icon {
    min-width: 1.5rem;
    min-height: 1.5rem;
}

.main-chat-item-btn-tag {
    display: flex;
}

.main-chat-item-btn-tag.reduce {
    display: none;
}

.main-chat-item-btn-tag-responsive {
    display: flex;
    align-self: center;
}

.main-chat-item-btn-tag-responsive.active {
    display: flex;
    align-self: center;
    width: auto;
}

.new-version-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
}

.new-version-info-wrapper {
    display: flex;
    width: 70%;
    height: 80%;
    border-radius: .625rem;
    flex-direction: column;
}

.new-version-info-wrapper button {
    display: flex;
    height: 2.5rem;
    width: 6.25rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: .625rem 1.875rem;
    font-weight: 1rem;
    font-weight: bold;
    align-self: flex-end;
    margin: .9375rem;
    border-radius: .375rem;
    cursor: pointer;
}

.new-version-info-wrapper-top {
    display: flex;
    width: 100%;
    flex: 2;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
}

.section-title {
    height: 2.8125rem;
    width: 100%;
    text-align: left;
    padding: .625rem 1.25rem;
    font-weight: 1rem;
    font-weight: bold;
}

.box-wrapper {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
}

.new-version-info-wrapper-bot {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 8;
    gap: 1.25rem;
    padding: .625rem 1.25rem;
    overflow-y: auto;
    align-items: flex-start;
    justify-content: flex-start;
}

.new-version-info-wrapper-bot::-webkit-scrollbar {
    width: .25rem;
}

.new-version-info-wrapper-bot::-webkit-scrollbar-track {
    background: transparent;
}

.new-version-info-wrapper-bot::-webkit-scrollbar-thumb {
    background-color: var(--light-theme-border-color);
    height: 50%;
    border-radius: 1.25rem;
}

.new-version-info-wrapper-bot-content-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 3.75rem;
    gap: 1.25rem;
    padding: .625rem 1.25rem;
    align-items: flex-start;
    justify-content: flex-start;
    transition: height 0.3s ease-out;
}

.new-version-info-wrapper-bot-content-top.expand {
    height: 200px;
}

.new-version-info-wrapper-bot-content-top-siwtch-btn {
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.new-version-info-wrapper-bot-content-top-siwtch-btn button {
    display: flex;
    height: 2.5rem;
    width: 1.875rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: .9375rem;
    font-weight: 1rem;
    font-weight: bold;
    align-self: center;
    border-radius: .375rem;
    cursor: pointer;
    border: none !important;
    box-shadow: none !important;
}

.new-version-info-wrapper-bot-content-top-siwtch-btn h1 {
    height: 100%;
    width: 100%;
    text-align: left;
    font-weight: 1rem;
    font-weight: bold;
    margin-top: 10px;
}

.new-version-info-wrapper-bot-content-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 3.75rem;
    gap: 1.25rem;
    padding: .625rem 1.25rem;
    align-items: flex-start;
    justify-content: flex-start;
    transition: height 0.3s ease-out;
}

.new-version-info-wrapper-bot-content-bottom.expand {
    height: 500px;
}


.new-version-info-wrapper-bot-container {
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 80px;
    max-width: 100%;
    height: 60px;
    min-height: 5rem;
    max-height: 100px;
    border-radius: .75rem;
    padding: 1.25rem;
    gap: .625rem;
    text-align: left;
    transition: height 0.3s ease-out;
}

.new-version-info-wrapper-bot-item {
    width: auto;
    min-width: 80px;
    max-width: 100%;
    height: auto;
    min-height: 5rem;
    border-radius: .75rem;
    padding: 1.25rem;
    gap: .625rem;
    text-align: left;
}

.new-version-info-wrapper-bot-item-bug {
    width: auto;
    min-width: 80px;
    max-width: 100%;
    height: auto;
    min-height: 5rem;
    max-height: 31.25rem;
    border-radius: .75rem;
    padding: 1.25rem;
    gap: .625rem;
    text-align: left;
}

.new-version-info-wrapper-bot-item-title {
    display: flex;
    flex-direction: row;
    gap: .625rem;
    align-items: center;
}

.new-version-info-wrapper-bot-item-title h1 {
    font-size: 1rem;
    font-weight: bold;
}

.new-version-info-wrapper-bot-item-icon-dark {
    color: var(--dark-theme-icons-color)
}

.new-version-info-wrapper-bot-item-icon-light {
    color: var(--light-theme-icons-color)
}

@media screen and (max-width: 1600px) {

    .main-info-wrapper {
        display: flex;
        width: 80%;
        justify-content: center;
        align-items: center;
        padding: 0 1.875rem 4.375rem 1.875rem;
    }

}

@media screen and (max-width: 1580px) {

    .main-info-component-top-texts {
        height: 5.4375rem;
        width: auto;
        max-width: 42.5625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }

    .main-info-component-top-texts-title {
        font-size: 3.125rem;
        font-weight: 700
    }

    .main-info-component-top-texts-p {
        font-size: 1rem;
        font-weight: 400
    }

    .main-info-component-box-content {
        margin-top: 1.25rem;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1200px) {

    .main-info-wrapper {
        display: flex;
        width: 100%;
    }

    .main-info-wrapper.max-imension {
        width: 100%;
    }

    .main-info-component-top-texts {
        height: 5.4375rem;
        width: auto;
        max-width: 42.5625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }

    .main-info-component-top-texts-title {
        font-size: 30px;
        font-weight: 700
    }

    .main-info-component-top-texts-p {
        font-size: .875rem;
        font-weight: 400
    }

    .main-info-component-box-content {
        flex-wrap: wrap;
    }

    .main-info-component-box-item {
        width: auto;
        max-width: 14.375rem;
        height: 10.375rem;
        border-radius: .75rem;
        padding: 1.25rem;
        gap: .625rem;
    }
}

@media screen and (max-width: 1030px) {

    .main-info-component-top-texts {
        height: 5.4375rem;
        width: auto;
        max-width: 42.5625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }

    .main-info-component-top-texts-title {
        font-size: 30px;
        font-weight: 700
    }

    .main-info-component-top-texts-p {
        font-size: .875rem;
        font-weight: 400
    }

    .main-info-component-box-content {
        flex-wrap: wrap;
    }

    .main-info-component-box-item h4 {
        font-size: 14px;
        font-weight: 600;
    }

    .main-info-component-box-item p {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
    }

    .main-info-component-box-item {
        width: auto;
        max-width: 11.25rem;
        height: 10.375rem;
        border-radius: .75rem;
        padding: 1.25rem;
        gap: .625rem;
    }

}

@media screen and (max-width: 620px) {

    .main-info-component-top-texts {
        height: 5.4375rem;
        width: auto;
        max-width: 42.5625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }

    .main-info-component-top-texts-title {
        font-size: 30px;
        font-weight: 700
    }

    .main-info-component-top-texts-p {
        font-size: .875rem;
        font-weight: 400
    }

    .main-info-component-box-content {
        flex-wrap: wrap;
    }

    .main-info-component-box-item {
        width: auto;
        max-width: 10rem;
        height: 10.375rem;
        border-radius: .75rem;
        padding: 1.25rem;
        gap: .625rem;
    }

    .main-info-component-box-item h4 {
        font-size: 14px;
        font-weight: 600;
    }

    .main-info-component-box-item p {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
    }



}

@media screen and (max-width: 570px) {

    .main-info-component {
        height: 60%;
        width: 90%;
        max-width: 71.25rem;
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-bottom: 10%
    }

    .main-info-component-top-texts {
        height: 5.4375rem;
        width: auto;
        max-width: 42.5625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }

    .main-info-component-top-texts-title {
        font-size: 1.25rem;
        font-weight: 700
    }

    .main-info-component-top-texts-p {
        font-size: 12px;
        font-weight: 400
    }

    .main-info-component-box-content {
        flex-wrap: wrap;
    }

    .main-info-component-box-item {
        width: auto;
        max-width: 7.5rem;
        height: 7.5rem;
        border-radius: .75rem;
        padding: .625rem;
        gap: .3125rem;
    }

    .main-info-component-box-item-top-icon {
        width: 1.125rem;
        height: 1.125rem;
    }

    .main-info-component-box-item-top {
        gap: .1875rem;
    }

    .main-info-component-box-item h4 {
        font-size: .75rem;
        font-weight: 600;
    }

    .main-info-component-box-item p {
        font-size: 9px;
        font-weight: 400;
        text-align: left;
    }



}

@media screen and (max-width: 460px) {

    .main-info-wrapper-content-bot-question-box-input-textarea::placeholder {
        font-size: 12px;
    }

    .main-info-component {
        height: 60%;
        width: 100%;
        max-width: 71.25rem;
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-bottom: 10%;
    }

    .main-info-component-top-texts {
        height: 5.4375rem;
        width: auto;
        max-width: 42.5625rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .main-info-component-top-texts-title {
        font-size: 1rem;
        font-weight: 700
    }

    .main-info-component-top-texts-p {
        font-size: 12px;
        font-weight: 400
    }

    .main-info-component-box-content {
        flex-wrap: wrap;
    }

    .main-info-component-box-item {
        width: auto;
        max-width: 6.25rem;
        height: 7.5rem;
        border-radius: .75rem;
        padding: .625rem;
        gap: .3125rem;
    }

    .main-info-component-box-item-top {
        gap: .125rem;
    }

    .main-info-component-box-item-top-icon {
        width: .9375rem;
        height: .9375rem;
    }

    .main-info-component-box-item h4 {
        font-size: .625rem;
        font-weight: 600;
    }

    .main-info-component-box-item p {
        font-size: 8px;
        font-weight: 400;
        text-align: left;
    }



}