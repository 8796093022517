.version-info {
    display: flex;
    height: 1.875rem;
    font-size: .75rem;
    width: auto;
    align-items: center;
    justify-content: center;
    margin-right: .3125rem;
}

.children-content {
    height: 100%;
    animation: fadein 1s;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sidebar-container2 {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}

.logo-athena {
    width: 6rem;
    height: 6rem;
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 .3125rem 0 .625rem;
}

.sidebar-content.resize {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 1.25rem;
    resize: none;
}

.sidebar-content-top {
    display: flex;
    height: 80%;
    max-height: 80%;
    min-height: 70%;
    width: 100%;
    margin-top: 1.25rem;
    justify-content: center;
}

.sidebar-content-top-load {
    height: auto;
    max-height: 100%;
    min-height: 70%;
    width: 100%;
}

.sidebar-content-new-chat {
    height: 4rem;
    width: auto;
    max-width: 17.6875rem;
    min-width: 4rem;
    min-height: 4rem;
    border-radius: 3.125rem;
    padding: 1.25rem;
    margin-top: .3125rem;
    margin-left: .3125rem;
}

.sidebar-content-new-chat-mobile {
    height: 4rem;
    width: auto;
    max-width: 17.6875rem;
    min-width: 4.0625rem;
    min-height: 4.0625rem;
    border-radius: 3.125rem;
    padding: 0 1.25rem 0 1.25rem;
    margin-top: .3125rem;
    display: none;
}

.sidebar-content-bottom {
    display: flex;
    flex-direction: row;
    height: 5rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    gap: .1875rem;
}

.sidebar-content-bottom.reduce {
    display: none;
}

.sidebar-content-top-replace {
    display: 'none';
}

.sidebar-content-top-replace.active {
    height: 70%;
    min-height: 70%;
    width: 100%;
    display: 'none';
}

.sidebar-content-top.reduce-dimension {
    display: none;
}

.sidebar-content-top-line {
    height: .1875rem;
    width: 100%;
    background-color: transparent;
}

.sidebar-changeDimensions {
    overflow: hidden;
    transition: width 0.3s ease-out;
    padding: 0 0 0 1.25rem;
}

.sidebar-changeDimensions.reduce-dimension {
    width: 6.25rem;
    justify-content: flex-start;
    align-items: flex-start;
}

.sidebar-mobile-component {
    display: none;
    height: 100%;
    margin-top: -4.25rem;
    width: 40%;
    min-width: 18.75rem;
    position: absolute;
    z-index: 50;
    padding: 6.25rem 1.25rem 3.125rem 1.25rem;
    left: 0;
    opacity: 0;
    transform: translateX(-130%);
    transition: 0.2s;
    transition-timing-function: ease-in;
    flex-direction: column;
}

.sidebar-mobile-component-content {
    display: flex;
}

.sidebar-mobile-component.reduce-dimension {
    transition: 0.25s;
    transition-timing-function: ease-out;
    transform: translateX(0);
    opacity: 1;

}

.sidebar-changeDimensions.mobile-expand {
    transition: 0.25s;
    transition-timing-function: ease-out;
    transform: translateX(0);
    opacity: 1;

}

.sidebar-mobile-component-content.hidden {
    display: none;
}

.display-children {
    display: flex;
}

@media screen and (width > 1700px) {

    .sidebar-content-new-chat {
        margin-left: 1.875rem;
    }
}

@media screen and (width < 1700px) and (width > 1600px) {

    .sidebar-content-new-chat {
        margin-left: .625rem;
    }
}

@media screen and (max-width: 1200px) {
    .sidebar-changeDimensions.reduce-dimension {
        display: none;
        opacity: 0;
        animation-delay: 1s;
        transform: translateX(-130%);
        transition-timing-function: ease-in;
        transition: 0.6s;
    }

    .display-children.hidden {
        display: flex;
    }

    .sidebar-changeDimensions {
        height: 100%;
        margin-top: -4.25rem;
        width: 290px;
        min-width: 250px;
        max-width: 290px;
        position: absolute;
        left: 0;
        z-index: 50;
        padding: 3.125rem 1.25rem 3.125rem 1.25rem;
        transition: 0.25s;
        transition-timing-function: ease-out;
        transform: translateX(0);
        opacity: 1;
        flex-direction: column;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-right: solid 1px;
    }

    .sidebar-content-top {
        display: flex;
        flex-direction: column;
        display: flex;
        height: 100%;
        max-height: 100%;
        min-height: 70%;
        width: 100%;
        margin-top: 1.25rem;
        justify-content: flex-start;
    }

    .sidebar-content-new-chat {
        display: none;
    }

    .sidebar-content-new-chat-mobile {
        display: flex;
        flex-direction: column;
    }

    .sidebar-content-bottom {
        display: none;
    }

}