.text-muted {
    color: #6c757d !important;
}

.text-success {
    color: #28a745 !important;
}

.content-user {
    display: flex;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 10px;
    border-radius: 3.125rem;
    padding: 1.25rem;
    font-size: 16px;
    font-weight: 400;
    width: 90%;
    max-width: 71.25rem;
    opacity: 0;
    animation: fadeInDown 0.15s ease-in-out forwards;

}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.content-ia {
    display: flex;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 10px;
    border-radius: 3.125rem;
    padding: 1.25rem;
    font-size: 16px;
    font-weight: 400;
    width: 90%;
    max-width: 71.25rem;
}

.grid.grid-flow-row ol ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.grid.grid-flow-row ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
}

.grid.grid-flow-row ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
}

.grid.grid-flow-row li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.grid.grid-flow-row ol li p {
    font-weight: bolder;
}

.grid.grid-flow-row ol>li::marker {
    color: #000000;
    font-weight: 600;
    padding-right: 0.5rem;
    position: absolute;
}

.flat-buttom-container {
    display: flex;
    flex-direction: row;
    height: 1.875rem;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    margin-top: .3125rem;
}

.copy-code-btn {
    font-size: 14px;
    font-weight: 400;
}

.float-options {
    display: flex;
    height: auto;
    width: 200px;
    padding: 1.25rem;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-top: -90px;
    border-radius: .75rem;
    z-index: 1000;

}


.float-box {
    display: flex;
    flex-direction: column;
    margin-right: -14.375rem;
    margin-top: -5.625rem;
    height: auto;
    max-height: 7.625rem;
    width: 12.5625rem;
    border-radius: 12px;
    padding: 1.25rem;
    gap: .3125rem;
    z-index: 30;
}

.markdown-container {
    max-width: 94% !important;
    white-space: pre-wrap !important;
    word-break: break-all !important;
    text-overflow: ellipsis !important;
}

.markdown-format {
    width: 100%;
    align-self: center;
}