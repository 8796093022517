
.main-layout {
    background: linear-gradient(201.93deg, #0A1C32 11.4%, #3C1333 45.49%, #020003 85.65%);
}

.main-layout-container {
    display: flex;
    flex: 1;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
}

#root {
    height: 100%;
}

.footer-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.message-block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.chat-container {
    height: 100vh; /* Establece la altura al 100% de la altura de la ventana */
    display: flex; /* Activa el modo flexbox */
}

.footer-info {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-size: 12px;
}

.user-info {
    color: #FFFFFF;
    font-weight: 400;
}
