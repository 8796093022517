.landing-container {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3.1rem;
    padding: 0 50px 0 50px;
}

.landing-container .wrapper-side-content {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}

.landing-container .wrapper-side-content .side-content {
    display: flex;
    flex-direction: column;
    width: 31.6rem;
    height: 18.9rem;
    justify-content: flex-start;
    align-items: 'flex-start';
    gap: 1.25rem;
}

.landing-container .wrapper-side-content .side-content .logo-athena-text {
    width: 13.8rem;
    height: 3.2rem;
}

.landing-container .wrapper-side-content .side-content h1 {
    text-align: start;
    font-weight: 700;
    font-size: 28px;
}

.landing-container .wrapper-side-content .side-content p {
    text-align: start;
    font-weight: 400;
    font-size: 16px;
}

.landing-container .wrapper-side-component-change-image {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image {
    height: auto;
    max-height: 100%;
    width: 100%;
    justify-content: end;
    align-items: start;
    display: flex;
    flex-direction: column;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image .img {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    width: 90%;
    height: 80%;
    max-width: 31.25rem;
    min-height: 25rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.75rem;
    z-index: 10;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-init {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 30rem;
    height: auto;
    max-height: 7.5rem;
    padding: 1.25rem;
    align-self: center;
    gap: 0.652rem;
    margin-left: -25%;
    margin-top: -5%;
    border-radius: 0.75rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 20;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 30rem;
    height: auto;
    max-height: 47.5rem;
    padding: 1.25rem;
    align-self: center;
    margin-left: -25%;
    margin-top: -20%;
    border-radius: 0.75rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: start;
    gap: 0.625rem;
    z-index: 20;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand-text {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 30rem;
    height: auto;
    max-height: 47.5rem;
    padding: 1.25rem;
    align-self: center;
    margin-left: -25%;
    margin-top: -20%;
    border-radius: 0.75rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: start;
    gap: 0.625rem;
    z-index: 20;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .athena-icon {
    display: flex;
    width: 3.125rem;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .text-code {
    display: flex;
    width: 90%;
}

.landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0.625rem;
    border: solid 1px lightgrey;
    border-radius: 0.75rem;
    padding: 30px 10px 0 10px;
    font-weight: bold;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: '50%';

}


.landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
    display: flex;
    width: 90%;
    max-width: 25rem;
}

.signin-btn {
    align-items: center;
    justify-content: center;
    width: 9rem;
    height: 2.5rem;
    border-radius: 4px;
    gap: 0.5rem;
    color: var(--general-theme-primary-text-color);
    font-weight: 400;
    font-size: 1rem;
    background-color: var(--general-theme-primary-bg-color);
}

@media screen and (max-width: 1500px) {

    .landing-container .wrapper-side-component-change-image .side-component-change-image {
        height: auto;
        max-height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        max-width: 30rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-init {

        width: 80%;
        max-width: 80%;
        align-self: flex-end;
        margin: -10% 0 0 0;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand {
        margin-top: -35%;
        width: 80%;
        max-width: 80%;
        align-self: flex-end;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 90%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 80%;
    }
}

@media screen and (max-width: 1400px) {

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 90%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 80%;
    }
}

@media screen and (max-width: 1150px) {
    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 85%;
    }
}

@media screen and (max-width: 1100px) {
    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 80%;
    }
}

@media screen and (max-width: 1050px) {
    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand {
        margin-top: -45%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 75%;
    }
}

@media screen and (max-width: 990px) {

    .landing-container .wrapper-side-content .side-content {
        display: flex;
        flex-direction: column;
        width: 31.6875rem;
        height: auto;
        max-height: 25rem;
        justify-content: flex-start;
        align-items: 'flex-start';
        gap: 1.25rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image {
        display: flex;
        flex-direction: column;
        height: auto;
        max-height: 100%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 60%;
    }

}

@media screen and (max-width: 850px) {


    .landing-container {
        flex-direction: column-reverse;
        padding: 0 1.25rem 0 1.25rem;
        gap: 0.625rem;
    }

    .landing-container .wrapper-side-content {
        display: flex;
        width: 100%;
        height: 50%;
        justify-content: flex-end;
        align-items: center;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image {
        height: auto;
        min-height: 50%;
        max-height: 100%;
        width: auto;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .landing-container .wrapper-side-content .side-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 18.9375rem;
        justify-content: flex-start;
        align-items: 'flex-start';
        gap: 1.25rem;
    }

    .landing-container .wrapper-side-component-change-image {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 21rem;
        justify-content: flex-start;
        align-items: center;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: end;
        width: 850px;
        height: 100%;
        max-width: 100%;
        min-height: 16rem;
        background-image: url("../assets/images/imgLanding1.jpeg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.75rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-init {
        margin-top: -5%;
        width: 100%;
        max-width: 90%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand {
        display: flex;
        flex-direction: row;
        margin-left: 2%;
        margin-top: -15%;
        width: 100%;
        max-width: 90%;
        height: auto;
        max-height: 12.5rem;
        gap: 1.25rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand-text {
        display: flex;
        flex-direction: row;
        margin-left: 2%;
        margin-top: -15%;
        width: 50rem;
        max-width: 90%;
        height: auto;
        max-height: 12rem;
        gap: 1.25rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-container {
        display: flex;
        flex-direction: row;
        height: 50%;
        max-height: 10rem;
        width: 90%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .text-code {
        display: flex;
        width: 60%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;
        border: 1 solid lightgrey;
        border-radius: 0.75rem;
        padding: 0.625rem;
        font-weight: bold;
        align-items: flex-start;
        width: 40%;
        max-width: 50%;
        height: auto;
        max-height: '50%';

    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 55%;
        height: 50%;
    }

}

@media screen and (max-width: 750px) {

    .landing-container .wrapper-side-content {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image {
        height: auto;
        max-height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .landing-container .wrapper-side-content .side-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 18.9375rem;
        justify-content: flex-start;
        align-items: 'flex-start';
        gap: 1.25rem;
    }

    .landing-container .wrapper-side-component-change-image {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 21rem;
        justify-content: flex-start;
        align-items: center;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: end;
        width: 100%;
        height: 100%;
        max-width: 43.75rem;
        min-height: 16rem;
        background-image: url("../assets/images/imgLanding1.jpeg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.75rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-init {
        max-width: 100%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand {
        display: flex;
        flex-direction: row;
        margin-left: 2%;
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: 12rem;
        gap: 1.25rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand-text {
        max-width: 100%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-container {
        display: flex;
        flex-direction: row;
        height: 50%;
        max-height: 10rem;
        width: 90%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;
        border: 1 solid lightgrey;
        border-radius: 0.75rem;
        padding: 0.625rem;
        font-weight: bold;
        align-items: flex-start;
        width: 40%;
        max-width: 50%;
        height: auto;
        max-height: '50%';

    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .text-code {
        display: flex;
        width: 60%;
        font-size: 14px;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 50%;
        height: 50%;
    }

}

@media screen and (max-width: 650px) {

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand {
        display: flex;
        flex-direction: row;
        margin-left: 2%;
        width: 100%;
        max-width: 100%;
        max-height: 14.375rem;
        gap: 1.25rem;
    }


    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .text-code {
        display: flex;
        width: 60%;
        font-size: 14px;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;
        border: 1 solid lightgrey;
        border-radius: 0.75rem;
        padding: 0.625rem;
        font-weight: bold;
        align-items: flex-start;
        width: 40%;
        max-width: 50%;
        height: auto;
        max-height: '50%';
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 45%;
        height: 45%;
    }

}

@media screen and (max-width: 570px) {
    .landing-container {
        gap: 1.875rem;
    }

    .landing-container .wrapper-side-content {
        display: flex;
        width: 100%;
        height: 50%;
        justify-content: flex-end;
        align-items: center;
    }

    .landing-container .wrapper-side-content .side-content .logo-athena-text {
        width: 7.5rem;
        height: auto;
    }

    .landing-container .wrapper-side-content .side-content h1 {
        text-align: start;
        font-weight: 700;
        font-size: 22px;
    }

    .landing-container .wrapper-side-content .side-content p {
        text-align: start;
        font-weight: 400;
        font-size: 14px;
    }

    .landing-container .wrapper-side-component-change-image {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 20.625rem;
        justify-content: flex-start;
        align-items: center;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: end;
        background-image: url("../assets/images/imgLanding1.jpeg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.75rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image {
        margin-top: 1.5rem;
    }


    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .text-code {
        display: flex;
        width: 60%;
        font-size: 13px;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;
        border: 1 solid lightgrey;
        border-radius: 0.75rem;
        padding: 0.625rem;
        font-weight: bold;
        align-items: flex-start;
        width: 40%;
        max-width: 50%;
        height: auto;
        max-height: '50%';

    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 35%;
        height: 45%;
    }
}

@media screen and (max-width: 540px) {

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .text-code {
        display: flex;
        width: 60%;
        font-size: 0.75rem;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;
        border: 1 solid lightgrey;
        border-radius: 0.75rem;
        padding: 0.625rem;
        font-weight: bold;
        align-items: flex-start;
        width: 40%;
        max-width: 50%;
        height: auto;
        max-height: '50%';

    }
}

@media screen and (max-width: 480px) {

    .landing-container {
        flex-direction: column-reverse;
        padding: 0 1.25rem 0 1.25rem;
        gap: 1.25rem;
    }

    .landing-container .wrapper-side-content {
        display: flex;
        width: 100%;
        height: 50%;
        justify-content: flex-end;
        align-items: center;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image {
        margin-top: 1.5rem;
        height: auto;
        min-height: 50%;
        max-height: 100%;
        width: auto;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-container {
        display: flex;
        flex-direction: row;
        height: 50%;
        max-height: 12.5rem;
        width: 90%;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand {
        margin-top: -30%;
    }


    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .text-code {
        display: flex;
        width: 60%;
        font-size: 12px;
    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .markdown-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;
        border: 1 solid lightgrey;
        border-radius: 0.75rem;
        padding: 0.625rem;
        font-weight: bold;
        align-items: flex-start;
        width: 40%;
        max-width: 50%;
        height: auto;
        max-height: '50%';

    }

    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 25%;
        height: 60%;
    }
}

@media screen and (max-width: 410px) {
    .landing-container .wrapper-side-component-change-image .side-component-change-image .img-content-expand .code-style {
        width: 20%;
        height: 60%;
    }
}

.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}

.change-image-component-list-container {
    width: 100%;
    height: auto;
    padding: 0.625rem;
    max-height: 80%;
    overflow-y: auto;
}

.change-image-component-list-container-ul-lists {
    margin-left: 1.25rem;
}

.change-image-component-list-container-ul-lists ul {
    margin-left: 1.25rem;
    list-style-type: disc;
}