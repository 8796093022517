@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --light-theme-background-color: #F3F8FF;
  --light-theme-surface-color: #FFFFFF;
  --light-theme-border-color: #C5C5C5;
  --light-theme-title-color: #121212;
  --light-theme-text-color: #303030;
  --light-theme-icons-color: #121212;


  --dark-theme-background-color: #121212;
  --dark-theme-surface-color: #242424;
  --dark-theme-border-color: #525252;
  --dark-theme-title-color: #F7F7F7;
  --dark-theme-text-color: #DFDFDF;
  --dark-theme-icons-color: #F7F7F7;


  --general-theme-primary-bg-color: #C41A90;
  --general-theme-primary-text-color: #FFFFFF;


}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--light-theme-title-color);
}

/* FUENTES DEL PROYECTO */
@font-face {
  font-family: roboto, sans-serif;
  src: url(../public/fonts/Roboto-Medium.ttf);
}

.roboto-regular {
  font-family: roboto, sans-serif;
}

@font-face {
  font-family: poppins;
  src: url(../public/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: poppins-bold;
  src: url(../public/fonts/Poppins-Bold.ttf);
}

.poppins-bold {
  font-family: poppins-bold;
}

.poppins-regular {
  font-family: poppins, sans-serif;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/*
* .light-theme manejará todas las configuraciones generales de los componentes para intercambiar solo valores claves
* de forma global, conviviendo con las hojas de estilos independientes de cada componente para otras configuraciones especiales.
*/
body.light-theme {
  background-color: var(--light-theme-background-color);
}

body.light-theme .themeNewVersionInfo {
  background-color: var(--light-theme-background-color);
  border: solid 0.5px var(--light-theme-border-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.light-theme .themeNewVersionInfo h1 {
  color: var(--light-theme-title-color);
}

body.light-theme .themeNewVersionInfo p {
  color: var(--light-theme-text-color);
}

body.light-theme .themeNewVersionInfo button {
  background-color: var(--light-theme-surface-color);
  border: solid 0.5px var(--light-theme-border-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.light-theme .themeNewVersionInfo-sections {
  background-color: var(--light-theme-surface-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.light-theme .themeLoaderIcon {
  border-top-color: var(--dark-theme-border-color);
}

body.light-theme .themeSkeletonIcon {
  color: var(--light-theme-icons-color);
}

body.light-theme .themeOrbit {
  background-color: var(--light-theme-surface-color);
}

body .themeNavbar {
  background-color: var(--light-theme-background-color);
  color: var(--light-theme-text-color);
}

body .themeLanding {
  background-color: var(--light-theme-background-color);
}

body.light-theme .themeLanding h1 {
  color: var(--light-theme-title-color);
}

body.light-theme .themeLanding p {
  color: var(--light-theme-text-color);
}

/*
* Thema para manejar cambio de modo de contenedor descriptivo de componente ChangeImageComponent en landing
*/

body .themeChangeImageComponent {
  background-color: var(--light-theme-background-color);
}

body.light-theme .themeChangeImageComponent h1 {
  color: var(--light-theme-title-color);
}

body.light-theme .themeChangeImageComponent p {
  color: var(--light-theme-title-color);
}

body.dark-theme .themeChangeImageComponent ul {
  color: var(--light-theme-title-color);
}

/*
* Thema para manejar cambio de modo de Sidebar
*/

body .themeSidebar {
  background-color: var(--light-theme-background-color);
}

body.light-theme .themeSidebar-new-chat {
  background-color: var(--light-theme-surface-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.light-theme .themeSidebar-mobile {
  border-right-color: var(--light-theme-border-color);
}

body.light-theme .themeSidebar-three-options {
  background-color: var(--light-theme-background-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.light-theme .themeSidebar-three-options-noequals {
  background-color: var(--light-theme-surface-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.light-theme .themeSidebar-three-options-selectors {
  background-color: var(--light-theme-surface-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: solid 0.5px var(--light-theme-border-color);
}

body.light-theme .themeSidebar h1 {
  color: var(--light-theme-title-color);
}

body.light-theme .themeSidebar p {
  color: var(--light-theme-title-color);
}

body.dark-theme .themeSidebar ul {
  color: var(--light-theme-title-color);
}

body.dark-theme .themeSidebar li {
  color: var(--light-theme-title-color);
}

/*
* Thema para manejar cambio de modo de Main
*/

body .themeMain {
  background-color: var(--light-theme-background-color);
}

body.light-theme .themeMain h1 {
  color: var(--light-theme-title-color);
}

body.light-theme .themeMain p {
  color: var(--light-theme-text-color);
}


body.light-theme .themeMain-content {
  background-color: var(--light-theme-surface-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.light-theme .themeMain-content-box-input {
  background-color: var(--light-theme-background-color);
  border: solid 0.5px var(--light-theme-border-color);
  box-shadow: var(--light-theme-surface-color) 0px -20px 40px 10px;
}

body.light-theme .themeMain-content-box-input textarea {
  color: var(--light-theme-title-color);
  font-family: poppins, sans-serif;
}

body.light-theme .themeMain-content-welcome-component-box-items {
  background-color: var(--light-theme-background-color);
  border: solid 0.5px var(--light-theme-border-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.light-theme .themeMain-icon-stop-chat {
  background-color: var(--light-theme-title-color);
}

body.light-theme .themeMain-content-welcome-component-box-items h4 {
  color: var(--light-theme-title-color);
}

body.light-theme .themeMain-content-welcome-component-box-items p {
  color: var(--light-theme-text-color);
}

body.light-theme .themeMain-content-welcome-component-box-items textarea::placeholder {
  color: var(--light-theme-title-color);
}

/*
* Theme para seccion chat en main
*/

body.light-theme .themeChatMain-user {
  background-color: var(--light-theme-surface-color);
  border: solid 0.5px var(--light-theme-border-color);
  color: var(--light-theme-title-color);
}

body.light-theme .themeChatMain-ia {
  background-color: var(--light-theme-surface-color);
  color: var(--light-theme-title-color);
}

/*
  * .dark-theme manejará todas las configuraciones generales de los componentes para intercambiar solo valores claves
  * de forma global, conviviendo con las hojas de estilos independientes de cada componente para otras configuraciones especiales.
  */
body.dark-theme {
  background-color: var(--dark-theme-background-color);
}

body.dark-theme .themeNewVersionInfo {
  background-color: var(--dark-theme-background-color);
  border: solid 0.5px var(--dark-theme-border-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.dark-theme .themeNewVersionInfo h1 {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeNewVersionInfo p {
  color: var(--dark-theme-text-color);
}

body.dark-theme .themeNewVersionInfo button {
  background-color: var(--dark-theme-surface-color);
  border: solid 0.5px var(--dark-theme-border-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.dark-theme .themeNewVersionInfo-sections {
  background-color: var(--dark-theme-surface-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.dark-theme .themeLoaderIcon {
  border-top-color: var(--light-theme-border-color);
}

body.dark-theme .themeSkeletonIcon {
  color: var(--dark-theme-icons-color);
}

body.dark-theme .themeOrbit {
  background-color: var(--dark-theme-surface-color);
}

body.dark-theme .themeNavbar {
  background-color: var(--dark-theme-background-color);
  color: var(--dark-theme-text-color);
}

body.dark-theme .themeLanding {
  background-color: var(--dark-theme-background-color);
}

body.dark-theme .themeLanding h1 {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeLanding p {
  color: var(--dark-theme-text-color);
}

/*
* Thema para manejar cambio de modo de contenedor descriptivo de componente ChangeImageComponent en landing
*/

body.dark-theme .themeChangeImageComponent {
  background-color: var(--dark-theme-surface-color);
}

body.dark-theme .themeChangeImageComponent h1 {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeChangeImageComponent p {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeChangeImageComponent ul {
  color: var(--dark-theme-title-color);
}


/*
* Thema para manejar cambio de modo de Sidebar
*/

body.dark-theme .themeSidebar {
  background-color: var(--dark-theme-background-color);
}

body.dark-theme .themeSidebar-new-chat {
  background-color: var(--dark-theme-surface-color);
}

body.dark-theme .themeSidebar-new-chat button {
  color: var(--dark-theme-text-color);
}

body.dark-theme .themeSidebar-mobile {
  border-right-color: var(--dark-theme-border-color);
}

body.dark-theme .themeSidebar-three-options {
  background-color: var(--dark-theme-background-color);
}

body.dark-theme .themeSidebar-three-options-noequals {
  background-color: var(--dark-theme-surface-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body.dark-theme .themeSidebar-three-options-selectors {
  background-color: var(--dark-theme-surface-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: solid 1px var(--dark-theme-border-color);
}

body.dark-theme .themeSidebar h1 {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeSidebar h2 {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeSidebar p {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeSidebar ul {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeSidebar li {
  color: var(--dark-theme-title-color);
}

/*
* Thema para manejar cambio de modo de Main
*/

body.dark-theme .themeMain {
  background-color: var(--dark-theme-background-color);
}

body.dark-theme .themeMain h1 {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeMain p {
  color: var(--dark-theme-text-color);
}

body.dark-theme .themeMain-content {
  background-color: var(--dark-theme-surface-color);
}

body.dark-theme .themeMain-content-box-input {
  background-color: var(--dark-theme-background-color);
  border: solid 0.5px var(--dark-theme-border-color);
  box-shadow: var(--dark-theme-surface-color) 0px -20px 40px 10px;
}

body.dark-theme .themeMain-content-box-input textarea {
  color: var(--dark-theme-title-color);
  font-family: poppins, sans-serif;
}

body.dark-theme .themeMain-content-welcome-component-box-items {
  background-color: var(--dark-theme-background-color);
  border: solid 0.5px var(--dark-theme-border-color);
  box-shadow: var(--dark-theme-surface-color) 0px -20px 40px 10px;
}

body.dark-theme .themeMain-icon-stop-chat {
  background-color: var(--dark-theme-title-color);
}

body.dark-theme .themeMain-content-welcome-component-box-items h4 {
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeMain-content-welcome-component-box-items p {
  color: var(--dark-theme-text-color);
}

body.dark-theme .themeMain-content-welcome-component-box-items textarea::placeholder {
  color: var(--dark-theme-title-color);
}

/*
* Theme para seccion chat en main
*/

body.dark-theme .themeChatMain-user {
  background-color: var(--dark-theme-surface-color);
  border: solid 0.5px var(--dark-theme-border-color);
  color: var(--dark-theme-title-color);
}

body.dark-theme .themeChatMain-ia {
  background-color: var(--dark-theme-surface-color);
  color: var(--dark-theme-title-color);
}